.fullscreenLoader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	.dot {
		min-width: 8px;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: #eee;
		margin: 2px;
	}
	.firstDot {
		animation: dot1 1.4s infinite both;
	}
	.secondDot {
		animation: dot1 1.4s infinite both;
		animation-delay: 0.2s;
	}
	.thirdDot {
		animation: dot1 1.4s infinite both;
		animation-delay: 0.4s;
	}

	@keyframes dot1 {
		0% {
			background: #1c308b;
		}
		50% {
			background: #ffffff;
		}
		100% {
			background: #1c308b;
		}
	}
}
