.root {
	min-height: 100%;
	box-sizing: border-box;
	padding: 0 8px 8px;
	display: flex;
	flex-direction: column;

	@media (min-width: 768px) {
		max-width: 768px;
		width: 100%;
		margin: auto;
	}
}

.wrapper {
	margin: 0 16px;
}

.group_title {
	@extend .wrapper;
	margin-bottom: 2px;
}
